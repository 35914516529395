
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

import TextEditor from '../../components/TextEditor';
import DatePicker from "react-datepicker";

function NewsUpdate() {

  const notificationId = useLocation().pathname.split("/")[3]

  const [notification, setNotification] = useState({
    name: "",
    town: "",
    date1: "",
    date2: "",
    description: "",
    image: "",
  });


  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndtDate] = useState(new Date());


  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        console.log(url + '/heros/' + notificationId)
        const res = await axios.get(url + '/heros/' + notificationId, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        setNotification(res.data)
        setStartDate(new Date(res.data.date1))
        setEndtDate(new Date(res.data.date2))
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()

    notification.date1 = startDate.toISOString().split('T')[0]
    notification.date2 = endDate.toISOString().split('T')[0]

    try {
      await axios.put(url + "/heros/" + notificationId, { ...notification, description: notification.description.replaceAll("<div>", '').replaceAll("</div>", '<br>').replaceAll("<br><br><br>", '<br><br>') }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate('/Heros')
    } catch (err) {
      console.log(err)
    }
  }



  function onChange(e) {
    setNotification(prev => ({ ...prev, ["description"]: e.target.value }))
  }


  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Оновити</button>
      </div>

      <div className='ImputWrapper'>ФІБ
        <input onChange={HandleChange} name="name" value={notification?.name}></input>
      </div>
      <div className='ImputWrapper'>Місто
        <input onChange={HandleChange} name="town" value={notification?.town}></input>
      </div>
      <div className='ImputWrapper' style={{ width: 200 }}>
        Дата народження:
        <DatePicker dateFormat={'dd/MM/yyyy'} selected={startDate} onChange={(date) => setStartDate(date)} />
        <br />
        Дата смерті:
        <DatePicker dateFormat={'dd/MM/yyyy'} selected={endDate} onChange={(date) => setEndtDate(date)} />
      </div>

      <div className='ImputWrapper'>Опис
        <TextEditor
          value={notification.description}
          onChange={onChange}
        />
      </div>

    </div>
  )
}

export default NewsUpdate
