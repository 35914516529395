import './App.css';
import MyHeader from './components/Header';
import Users from './pages/Users';
import PasswordPage from './pages/Password';

import Notifications from './pages/Notifications/Notifications';
import NotificationsAdd from './pages/Notifications/NotificationsAdd';
import NotificationsUpdate from './pages/Notifications/NotificationsUpdate';

import News from './pages/NewsPodii/News';
import NewsAdd from './pages/NewsPodii/NewsAdd';
import NewsUpdate from './pages/NewsPodii/NewsUpdate';

import Podii from './pages/NewsPodii/Podii';
import PodiiAdd from './pages/NewsPodii/PodiiAdd';
import PodiiAddUpdate from './pages/NewsPodii/PodiiUpdate';

import DailyDef from './pages/Daily/DailyDef';
import Daily from './pages/Daily/Daily';
import DailyAdd from './pages/Daily/DailyAdd';
import DailyUpdateTitle from './pages/Daily/DailyUpdateTitle';
import DailyUpdateText from './pages/Daily/DailyUpdateText';
import DailyAddImage from './pages/Daily/DailyAddImage';
import DailyUpdateCoordinates from './pages/Daily/DailyUpdateCoordinates';
import DailyUpdateLink from './pages/Daily/DailyUpdateLink';

import Heros from './pages/Heros/Heros';
import HeroAdd from './pages/Heros/HeroAdd';
import HeroUpdate from './pages/Heros/HeroUpdate';


import PoslugiDef from './pages/Poslugi/PoslugiDef';
import Poslugi from './pages/Poslugi/Poslugi';

import SocialDef from './pages/Social/SocialDef';
import Social from './pages/Social/Social';

import TurismDef from './pages/Turism/TurismDef';
import Turism from './pages/Turism/Turism';

import Poll from './pages/Poll/Poll';
import PollAdd from './pages/Poll/PollAdd';
import PollDetails from './pages/Poll/PollDetails';
import PollImage from './pages/Poll/PollImage';

import Petition from './pages/petition/Petition';
import PetitionDetails from './pages/petition/PetitionDetails';

import Problem from './pages/Problems/Problem';
import ProblemKategories from './pages/Problems/ProblemKategories';
import ProblemDetails from './pages/Problems/ProblemDetails';


import CNAP from './pages/CNAP/CNAP';
import CNAPKategories from './pages/CNAP/CNAPKategory';
import CNAPAdd from './pages/CNAP/CNAPAdd';
import CNAPdetails from './pages/CNAP/CNAPdetails';
import CNAPkontacts from './pages/CNAP/CNAPkontacts';
import CNAPKalendar from './pages/CNAP/CNAPKalendar';
import CNAPaddImage from './pages/CNAP/CNAPaddImage';
import VerifyList from './pages/Verification/VerifyPage';
import VerifyUpdate from './pages/Verification/VerifyUpdate';

import Options from './components/Options';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import LoginImg from './assets/LoginImage.png';
import axios from 'axios';
import url from './url';

import { Link } from 'react-router-dom';
import React from 'react';
import Boyarka from './assets/Logo.png';
import Irpin from './assets/irpin.png';
import Bucha from './assets/bucha.png';
import Vishnyve from './assets/vishnyve.png';
import Gatne from './assets/gatne.png';


function App() {

  const [logined, setLogined] = useState(false)
  const [failde, setFailed] = useState(false)

  const [level, setLevel] = useState(0)
  // console.log("level:", level)

  const [notification, setNotification] = useState({
    login: "",
    password: "",
  });
  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const handleClick = async e => {
    e.preventDefault()

    const ports = ['8800', '8801', '8802', '8803', '8804']
    for (let i = 0; i < ports.length; i++) {
      try {
        const res = await axios.get("https://gromadia.in.ua:" + ports[i] + '/login', { headers: { email: notification.login, password: notification.password } })
        console.log(res.data)
        if (res.data == "succsesAdmin") {
          setLevel(2)
          setLogined(true)
          Cookies.set('admingromadia.in.uaDatabase', 'https://gromadia.in.ua:' + ports[i]);
          Cookies.set('admingromadia.in.email', notification.login);
          Cookies.set('admingromadia.in.password', notification.password);
          window.location.reload();
          return;
        } else if (res.data == "succsesCnap") {
          setLevel(1)
          setLogined(true)
          Cookies.set('admingromadia.in.uaDatabase', 'https://gromadia.in.ua:' + ports[i]);
          Cookies.set('admingromadia.in.email', notification.login);
          Cookies.set('admingromadia.in.password', notification.password);
          window.location.reload();
          return;
        } else {
          setFailed(true)
        }
      } catch (e) {

      }
    }
    setFailed(true)
  }

  useEffect(() => {
    async function fun() {
      try {
        const res = await axios.get(url + '/login', {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        if (res.data == "succsesAdmin") {
          setLevel(2)
          setLogined(true)

        }else  if (res.data == "succsesCnap") {
          setLevel(1)
          setLogined(true)

        } else {

        }
      } catch (e) {

      }
    }
    fun();
  }, [])

  return (
    <div className="App">
      {
        logined ?

          <Router>
            <header className='Header'>
              <div className='HeaderBlock'>
                {url.includes(8800) &&
                  <div className='Logo'>
                    <img alt='Boyarka' src={Boyarka} style={{ width: 50, height: 50 }} />
                    <h3>Боярська міська територіальна громада</h3>
                  </div>}
                {url.includes(8801) &&
                  <div className='Logo'>
                    <img alt='Irpin' src={Irpin} style={{ width: 50, height: 50 }} />
                    <h3>Ірпінська міська територіальна громада</h3>
                  </div>}
                {url.includes(8802) &&
                  <div className='Logo'>
                    <img alt='Vishnyve' src={Vishnyve} style={{ width: 50, height: 50 }} />
                    <h3>Вишнева міська громада</h3>
                  </div>}
                {url.includes(8803) &&
                  <div className='Logo'>
                    <img alt='Gatne' src={Gatne} style={{ width: 50, height: 50 }} />
                    <h3>Гатненська сільська громада</h3>
                  </div>}
                {url.includes(8804) &&
                  <div className='Logo'>
                    <img alt='Bucha' src={Bucha} style={{ width: 50, height: 50 }} />
                    <h3>Бучанська міська громада</h3>
                  </div>}
                <div className='LinkWrapper'>
                  <Link to='https://t.me/gromadia' target='_black'>
                    <div className='Link'>Допомога</div>
                  </Link>
                  <Link to='/Password'>
                    <div className='Link' style={{ backgroundColor: "#D9D9D9" }}>Налаштування</div>
                  </Link>
                  <button onClick={() => {
                    setLogined(false)
                    Cookies.set('admingromadia.in.email', "");
                    Cookies.set('admingromadia.in.password', "");
                  }}>
                    <div className='Link' style={{ backgroundColor: "#710000", color: '#fff' }}>Вихід</div>
                  </button>

                </div>
              </div>
            </header>
            <div className='mainBlock'>
              {level > 1 &&
                <Options />
              }
              <div className='RouteWrapper'>
                {level == 1 ?
                  <Routes>

                    <Route path='/' element={<CNAP />} />
                    <Route path='/CNAP/CNAPKategories' element={<CNAPKategories />} />
                    <Route path='/CNAP/CNAPdetails/:id' element={<CNAPdetails />} />
                    <Route path='/CNAP/CNAPAdd' element={<CNAPAdd />} />
                    <Route path='/CNAP/CNAPAddImage' element={<CNAPaddImage />} />
                    <Route path='/CNAP/CNAPKalendar' element={<CNAPKalendar />} />
                    <Route path='/CNAP/CNAPkontacts' element={<CNAPkontacts />} />
                    <Route path='/CNAP' element={<CNAP />} />

                  </Routes > :

                  <Routes>
                    <Route path='/' element={<Users />} />
                    <Route path='/Notifications' element={<Notifications />} />
                    <Route path='/Notifications/NotificationsAdd' element={<NotificationsAdd />} />
                    <Route path='/Notifications/NotificationsUpdate/:id' element={<NotificationsUpdate />} />

                    <Route path='/NewsPodii' element={<News />} />
                    <Route path='/NewsPodii/NewsAdd' element={<NewsAdd />} />
                    <Route path='/NewsPodii/NewsUpdate/:id' element={<NewsUpdate />} />

                    <Route path='/NewsPodii/Podii' element={<Podii />} />
                    <Route path='/NewsPodii/PodiiAdd' element={<PodiiAdd />} />
                    <Route path='/NewsPodii/PodiiUpdate/:id' element={<PodiiAddUpdate />} />

                    <Route path='/info/:id' element={<DailyDef />} />
                    <Route path='/info/:id/infoShow/:id' element={<Daily />} /> 

                    <Route path='/info/:id/infoAdd/:id' element={<DailyAdd />} />
                    <Route path='/info/:id/infoUpdateTitle/:id' element={<DailyUpdateTitle />} />
                    <Route path='/info/:id/infoUpdateText/:id' element={<DailyUpdateText />} />
                    <Route path='/info/:id/infoAddImage/:id' element={<DailyAddImage />} />
                    <Route path='/info/:id/infoUpdateCoordinates/:id' element={<DailyUpdateCoordinates />} />
                    <Route path='/info/:id/infoUpdateLink/:id' element={<DailyUpdateLink />} />

                    <Route path='/Heros' element={<Heros />} />
                    <Route path='/Heros/HeroAdd' element={<HeroAdd />} />
                    <Route path='/Heros/HeroUpdate/:id' element={<HeroUpdate />} />
                    {/* <Route path='/Poslugi/' element={<PoslugiDef />} />
                    <Route path='/Poslugi/PoslugiShow/:id' element={<Poslugi />} />

                    <Route path='/Social/' element={<SocialDef />} />
                    <Route path='/Social/SocialShow/:id' element={<Social />} />

                    <Route path='/Turism/' element={<TurismDef />} />
                    <Route path='/Turism/TurismShow/:id' element={<Turism />} />PollDetails */}

                    <Route path='/Poll' element={<Poll />} />
                    <Route path='/Poll/PollAdd' element={<PollAdd />} />
                    <Route path='/Poll/PollDetails/:id' element={<PollDetails />} />
                    <Route path='/Poll/PollImage/:id' element={<PollImage />} />

                    <Route path='/Petition' element={<Petition />} />
                    <Route path='/Petition/PetitionDetails/:id' element={<PetitionDetails />} />

                    <Route path='/Problem' element={<Problem />} />
                    <Route path='/Problem/ProblemKategories' element={<ProblemKategories />} />
                    <Route path='/Problem/ProblemDetails/:id' element={<ProblemDetails />} />

                    <Route path='/CNAP' element={<CNAP />} />
                    <Route path='/CNAP/CNAPKategories' element={<CNAPKategories />} />
                    <Route path='/CNAP/CNAPdetails/:id' element={<CNAPdetails />} />
                    <Route path='/CNAP/CNAPAdd' element={<CNAPAdd />} />
                    <Route path='/CNAP/CNAPAddImage' element={<CNAPaddImage />} />
                    <Route path='/CNAP/CNAPKalendar' element={<CNAPKalendar />} />
                    <Route path='/CNAP/CNAPkontacts' element={<CNAPkontacts />} />

                    <Route path='/VerifyList' element={<VerifyList />} />
                    <Route path='/VerifyUpdate/:id' element={<VerifyUpdate />} />
                    <Route path='/Password' element={<PasswordPage />} />
                  </Routes >
                }
              </div>

            </div>
          </Router>
          : <div className='LoginBlock' style={{ flexDirection: 'column' }}>

            <div className='LoginWrapper'>
              <img src={LoginImg} style={{ marginBottom: 48 }} alt="gromadia" />
              <div className='LoginTitle'>ГромадіЯ <h1 style={{ fontWeight: 400, marginLeft: 4 }}> - панель управління </h1></div>
              Логін
              <input onBlur={HandleChange} name="login" ></input>
              Пароль
              <input onBlur={HandleChange} type="password" name="password"></input>
              {failde && <span style={{ fontSize: 20, color: 'red' }}>Невірний пароль</span>}
              <button onClick={handleClick} className='LoginButton'>Вхід</button>
            </div>

          </div>

      }
    </div>
  );
}

export default App;
